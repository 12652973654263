li {
    margin: 0 0 0 5px;
    padding-left: inherit;
}

.navbar-nav > li {
    float: left;
}

.nav > li {
    position: relative;
    display: block;
}