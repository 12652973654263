@import url(https://fonts.googleapis.com/css2?family=Spinnaker&display=swap);
li {
    margin: 0 0 0 5px;
    padding-left: inherit;
}

.navbar-nav > li {
    float: left;
}

.nav > li {
    position: relative;
    display: block;
}
pre > code {
    font-family: monospace;
}
.page-container {
    margin: 40px 40px 40px 40px;
}
/* To reset HubSpot Popup Banner's default animation effect */
body .leadinModal.leadinModal-theme-top {
  top: auto;
  bottom: 0;
  z-index: 99999999999;
}

.leadinModal.leadinModal-theme-top .leadinModal-content {
 -webkit-animation-name: leadinModal-reset!important;
         animation-name: leadinModal-reset!important;
}
.leadinModal.leadinModal-theme-top.leadinModal-closing,
.leadinModal.leadinModal-theme-top.leadinModal-closing .leadinModal-content{
    -webkit-animation-name: leadinModal-fadeout!important;
            animation-name: leadinModal-fadeout!important;
}

@-webkit-keyframes leadinModal-reset {
    0% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 0;
    }
    1% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 0;
    }
    2% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 1;
    }
  }

@keyframes leadinModal-reset {
    0% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 0;
    }
    1% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 0;
    }
    2% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 1;
    }
  }
#root > [data-reactroot] {height: 100%;}

.no-margin {
    margin: 0 !important;
}

body {
    font-family: "Spinnaker", monospace !important;
}


.btn {
    font-weight: 300;
    letter-spacing: 0.75px !important;
    border: 1px solid;
    border-image: none;
    border-image: initial;
    padding: 10px 23px;
    outline: none !important;
    margin: 5px 5px 0 0;
}

.btn-primary {
    color: white;
    background: #00081C none !important;
    border-color: #00081C !important;
}

.btn-primary:hover {
    background: #F43563 none !important;
    border-color: #F43563 !important;
}

.btn-info {
    color: white;
    background: #F43563 none !important;
    border-color: #F43563 !important;
}

.btn-default {
    background: #e0e0e0 none !important;
    border-color: #e0e0e0 !important;
}

.btn-warning {
    color: white;
    background: #F43563 none !important;
    border-color: #F43563 !important;
}

.page-header {
    margin-top: 20px;
}

.title {
    font-weight: 600;
    line-height: 1.5em;
    letter-spacing: 1px;
}

.navbar {
    line-height: 50px;
    font-size: 17px;
    /* padding: 5px 0; */
    font-weight: 300;
    color: #F43563;
    position: relative;
    display: block;
    min-height: 50px;
    margin-bottom: 20px;
  /*  text-shadow: 0 1px 0 rgba(255, 255, 255, .25); */
}

.navbar-brand {
    float: left;
    height: 50px;
    font-size: 18px;
    line-height: 20px;
    color: #00081C !important;
    margin-right: unset;
}

.navbar-default {
    background-color: #00081C;
   /* box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075); */
    border-color: #e7e7e7;
}

.navbar-header {
    float: left;
}

.navbar-nav {
    float: left;
    display: block;
    margin: 0;
}

.navbar-nav > .active > a {
    background-image: none !important;
    background-color: inherit !important;
    box-shadow: none !important;
    color: #F43563 !important;
}


.navbar a {
    color: white;
}

.levels-list-title {
    font-weight: 500;
    letter-spacing: 0.75px !important;
}
.levels-list {
    font-weight: 400;
    letter-spacing: 0.75px !important;
}
.levels-list div {
    margin-bottom: 10px;
}

.levels-list a {
    color: #00081C;
}

.levels-list a:hover {
    color: #00081C;
}

.footer {
    background-color: #00081C;
    font-weight: 300;
    letter-spacing: 0.75px !important;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0; 
    z-index: 1030
}

.footer a {
    color: white !important;
}

.footer a:hover {
    text-decoration: underline;
}

.level-title, .difficulty {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.level-title > .title > .label {
    font-weight: 400;
    font-size: 10px !important;
}


.form-control {
    width: 30% !important;
}

a {
    color: #F43563;
}

a:hover {
    color: #F43563;
}
.scoreboard {
  border-collapse: collapse;
  width: 100%;
}

.scoreboard td, .scoreboard th {
  border: 1px solid #ddd;
  padding: 8px;
}

.scoreboard tr:nth-child(even){background-color: #f2f2f2;}

.scoreboard tr:hover {background-color: #ddd;}

.scoreboard th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #00081C;
  color: white;
}
